@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: 'Nunito', 'Red Hat Display', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

#scroller * {
    overflow-anchor: none;
}

#anchor {
    overflow-anchor: auto;
    height: fit-content;
}
code {
    font-family: 'Red Hat Display', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.no-margin {
    margin: 0px !important;
}

.z-index-50 {
    z-index: 5000 !important;
}
.z-index-40 {
    z-index: 20 !important;
}

.bg-dashboard {
    background-image: url('./assets/background.jpg');

    background-position: center;
    opacity: 0.5; /* Adjust the opacity value as needed */
}
.bg-error {
    background-image: url('./assets/subtleBG.jpg');

    background-position: center;
    opacity: 0.2; /* Adjust the opacity value as needed */
}

.bg-user {
    background-image: url('./assets/User.png');
}
.bg-company {
    background-image: url('./assets/Rectangle1.png');
}
.bg-company-green {
    background-image: url('./assets/RectangleGreen.png');
}
.bg-projects {
    background-image: url('./assets/Projects.png');
}

.bg-products {
    background-image: url('./assets/Products.png');
}

.bg-intelcount {
    background-image: url('./assets/IntelCount.png');
}

.bg-analysts {
    background-image: url('./assets/Analysts.png');
}

.truncate-multiline {
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to display before truncating */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-multiline-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to display before truncating */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes movetoLeft {
    0% {
        opacity: 0.5;
        transform: translateX(200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        scale: 0.6;
    }
    100% {
        opacity: 1;
        scale: 1;
    }
}

@keyframes moveUp {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes spinRight {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

.animate_move_to_Left {
    animation: movetoLeft 0.8s ease-in-out;
}
.animate_zoom_in {
    animation: zoomIn 0.1s ease-in-out;
}
.animate_fade_up {
    animation: moveUp 1s ease-in-out;
}

.animate_slow_spin {
    animation: spinRight 5s ease infinite;
}

.custom-scroll {
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #7cc576;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #7cc576;
    }
}

.line-height {
    line-height: 12px !important;
}

.leaflet-container {
    background-color: white !important;
}

.inner-shadow {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.454);
}

.Table-Style {
    .lffUrX {
        height: 10px !important;
        min-height: 20px;
    }
}

.custom-header {
    .sc-dmyDmy {
        // display: none!important;
        background-color: #777777 !important;
        color: white;
        font-size: 1.2em;
    }

    .bFyntL {
        flex-grow: 1;
        width: 'fit-content';
        white-space: nowrap;
    }
}

.pdf-viewer-wrapper {
    width: 50%;
    height: 100%;
    object-fit: contain;
}

.Card-Styling {
    width: 100%;
    white-space: normal;
    // margin-top: -10px;
    // background-color: aqua;

    .rdt_TableHeadRow {
        display: none;
    }
    .rdt_TableRow {
        display: flex;
        flex-direction: column;
        // width: 100%;
        white-space: wrap !important;
        // margin-top: 1px;
        // padding: 10px;
    }
    .bElfLb {
        padding: 0 0;
    }

    .rdt_Table {
        width: 100%;
        background-color: transparent;
    }
    .rdt_TableBody {
        // width: 100%;
    }

    .sc-fLsdDA {
        // display: none;
        // width: 100%;
        // white-space: normal;
    }

    .iWyFZZ div:first-child {
        white-space: normal;
        width: 100%;
        justify-content: space-between;
    }
}

.custom-pagination {
    .dOmuDp,
    .bnNclJ {
        display: none;
    }
    .cQAWmj {
        justify-content: space-evenly;
    }
}

.wrapping-style {
    .custom-header .bFyntL {
        white-space: normal !important;
    }
}

.Row-Hover {
    .rdt_TableRow {
        cursor: pointer;
        :hover {
            background-color: #7cc576;
            color: white;
        }
    }
}

.radio-inputs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: #eee;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 300px;
    font-size: 14px;
}

.radio-inputs .radio {
    flex: 1 1 auto;
    text-align: center;
}

.radio-inputs .radio input {
    display: none;
}

.radio-inputs .radio .name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    padding: 0.2rem 0;
    color: rgba(51, 65, 85, 1);
    transition: all 0.15s ease-in-out;
}

.radio-inputs .radio input:checked + .name {
    background-color: #7cc576;
    color: white;
    font-weight: 600;
}

/* checkbox settings 👇 */

.ui-checkbox {
    --primary-color: #1677ff;
    --secondary-color: #fff;
    --primary-hover-color: #4096ff;
    /* checkbox */
    --checkbox-diameter: 20px;
    --checkbox-border-radius: 5px;
    --checkbox-border-color: #d9d9d9;
    --checkbox-border-width: 1px;
    --checkbox-border-style: solid;
    /* checkmark */
    --checkmark-size: 1.2;
}

.ui-checkbox,
.ui-checkbox *,
.ui-checkbox *::before,
.ui-checkbox *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.ui-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: var(--checkbox-diameter);
    height: var(--checkbox-diameter);
    border-radius: var(--checkbox-border-radius);
    background: var(--secondary-color);
    border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
}

.ui-checkbox::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
    box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
    border-radius: inherit;
    opacity: 0;
    -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ui-checkbox::before {
    top: 40%;
    left: 50%;
    content: '';
    position: absolute;
    width: 4px;
    height: 7px;
    border-right: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
    -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
    transform: translate(-50%, -50%) rotate(45deg) scale(0);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}

/* actions */

.ui-checkbox:hover {
    border-color: var(--primary-color);
}

.ui-checkbox:checked {
    background: var(--primary-color);
    border-color: transparent;
}

.ui-checkbox:checked::before {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    -ms-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ui-checkbox:active:not(:checked)::after {
    -webkit-transition: none;
    -o-transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: none;
    opacity: 1;
}
.rdt_Pagination {
    border-radius: 7px;
}

.container {
    cursor: pointer;
    width: fit-content;
}

.container input {
    display: none;
}

.container svg {
    overflow: visible;
}

.path {
    fill: none;
    stroke: #7cc576;
    stroke-width: 6;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
    stroke-dasharray: 241 9999999;
    stroke-dashoffset: 0;
}

.container input:checked ~ svg .path {
    stroke-dasharray: 70.5096664428711 9999999;
    stroke-dashoffset: -262.2723388671875;
}

.table-styling {
    .jiAnER {
        line-height: 0 !important;
        padding: 2px 0 !important;
        line-break: 0 !important;
        margin: 0 !important;
        height: fit-content !important;
        min-height: 0px !important;
    }
    .cfvlby {
        line-height: 0 !important;
        padding: 0 !important;
        line-break: 0 !important;
        margin: 0 !important;
        height: fit-content !important;
        min-height: 0px !important;
        font-size: 0.6em;
        font-weight: bold;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.result-item {
    // border: 1px solid #ddd;
    background: white !important;
    padding: 12px;
    margin-bottom: 4px;
    border-radius: 4px;
}

.result-item h3 {
    font-size: 1.2em;
    margin-bottom: 8px;
}

.result-item p {
    margin: 4px 0;
}

.result-item img {
    margin-top: 8px;
    border-radius: 4px;
}

/* GoogleCustomSearch.css */

/* Customize the refinement labels (e.g., background color, text color) */
.gsc-refinementHeader {
    margin: 5px !important;
}

.gcse-search {
    max-width: 600px;
    margin: 0 auto;
}
#gs_tti50 {
    padding: 10px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: 0.3px solid #7cc576 !important;
    border-right: none !important;
    width: 100% !important;
    margin: 0 !important;
    background-color: rgba(213, 213, 213, 0.353) !important;
}

table.gsc-search-box td.gsc-input {
    padding-right: 7px !important;
}
.gsib_b {
    background-color: rgba(213, 213, 213, 0.353) !important;
    border: 0.3px solid #7cc576 !important;

    border-left: none !important;
}
.gscb_a {
    color: #7cc576 !important;
    // background-color: #7cc576;
    cursor: pointer;
}
#gsc-i-id1 {
    height: 100% !important;
}

.gsib_a {
    background-color: rgba(213, 213, 213, 0.353) !important;
    padding: 10px !important;
    border: 1px solid #7cc576;
    border-right: none !important;
}
/* Make the input field transparent */
.gsc-control-cse input.gsc-input {
    width: 100% !important;
    height: 100% !important;
    font-size: 16px !important;
    display: hidden !important;
    padding: 0px !important;
    background-color: rgba(213, 213, 213, 0.058) !important;
    opacity: 0.8 !important;
    color: #000000 !important;
}
.gsc-input-box {
    border: 2px solid #7cc576;
    border-radius: 5px !important;
    background: white !important;
    opacity: 90% !important;
    padding: 0 !important;
}
.gsc-control-cse {
    background-color: transparent !important;
    border: transparent !important;
    padding: 0 !important;
}

/* Style the search button */
.gsc-control-cse button.gsc-search-button {
    padding: 16px 20px !important;
    border: none !important;
    border-radius: 5px !important;
    background-color: #7cc576 !important;
    color: white !important;
    font-size: 16px !important;
    cursor: pointer !important;
}

/* Remove background from the results wrapper */
.gsc-results-wrapper-visible {
    margin-top: 20px !important;
    // background-color: transparent !important;
}

/* Remove background from individual results */
.gs-webResult.gs-result {
    border: none !important;
    padding: 10px !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    margin-bottom: 10px !important;
}

/* Customize the snippet text */
.gs-webResult.gs-result .gs-snippet {
    font-size: 14px !important;
    color: #fff !important; /* Change text color for better visibility */
}

/* Customize the title link */
.gs-webResult.gs-result a.gs-title {
    color: #007bff !important; /* Link color */
}

.rdt_TableCol_Sortable span {
    // display:none
    opacity: 0.3 !important;
}
